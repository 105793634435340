.popup-form__wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-form {
  font-family: 'Open Sans', 'Arial', sans-serif;
  max-width: 990px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  border: 4px solid #ff9900;
  border-radius: 5px;
  box-shadow: 0 0 32px 0 rgba(0, 0, 0, 0.9);
  background-color: #fff;
  padding: 50px;
  @media (max-width: 768px) {
    flex-direction: column;
    padding: 25px;
  }

  &__img {
    height: auto;
    max-height: 351px;
    max-width: 100%;
    margin-right: 50px;
    @media (max-width: 768px) {
      margin-bottom: 15px;
      margin-right: 0;
    }
  }
  &__content {
    margin-top: -3px;
  }
  &__title {
    color: #000;
    font-size: 27px;
    letter-spacing: 0.2px;
    font-weight: bold;
    margin: 0 0 26px 0;
    @media (max-width: 768px) {
      text-align: center;
    }
  }
  &__info {
    color: #000;
    font-size: 15px;
    letter-spacing: 0.1px;
    line-height: 25px;
    text-transform: uppercase;
    font-weight: normal;
    margin: 0 0 25px 0;
  }
  &__text {
    color: #000;
    font-weight: bold;
    font-size: 13px;
    line-height: 24px;
    letter-spacing: 0.1px;
    margin: 0 0 24px 0;
  }

  &__container {
    margin: 0 0 0 2px;
  }

  &__group-line {
    display: flex;
    justify-content: space-between;
    margin-bottom: 13px;
    @media (max-width: 1000px) {
      flex-direction: column;
      margin-bottom: 0;
    }
  }

  &__group-line2 {
    display: flex;
    justify-content: space-between;
    @media (max-width: 1000px) {
      flex-direction: column;
    }
  }

  &__group {
    display: flex;
    flex-direction: column;
    @media (max-width: 1000px) {
      margin-bottom: 10px;
    }
    @media (max-width: 768px) {
      width: 70%;
      margin: 0 auto 10px;
    }
    @media (max-width: 500px) {
      width: 100%;
    }
  }

  &__group2 {
    display: flex;
    flex-direction: row;
    @media (max-width: 1000px) {
      margin: 0 auto;
    }
  }

  &__group3 {
    display: flex;
    flex-direction: column;
    margin-right: 20px;
  }

  &__label, &__label2 {
    display: inline-block;
    font-size: 13px;
    color: #737472;
    max-width: 100%;
    margin-bottom: 10px;
  }

  &__label2 {
    margin-left: 10px;
  }

  &__name, &__email, &__date, &__month {
    display: inline-block;
    outline: none;
    font-size: 18px;
    padding: 8px 5px;
    border: 1px solid #a6a6a6;
    border-radius: 1px;
  }

  &__name {
    width: 220px;
  }

  &__email {
    width: 215px;
  }

  &__date {
    width: 38px;
  }

  &__month {
    width: 160px;
  }

  &__name, &__email {
    @media (max-width: 1000px) {
      width: 100%;
      box-sizing: border-box;
    }

  }

  &__submit {
    font-family: 'Open Sans', 'Arial', sans-serif;
    outline: none;
    font-size: 16px;
    padding: 9px 29px;
    background-color: #ff9900;
    color: #fff;
    align-self: flex-end;
    border: none;
    transition: 0.5s;
    cursor: pointer;
    &:hover {
      background-color: #FFCD20;
      transition: 0.5s;
    }
    @media (max-width: 1000px) {
      width: 80%;
      align-self: center;
    }
    @media (max-width: 768px) {
      width: 70%;
    }
    @media (max-width: 500px) {
      width: 90%;
    }
  }


  select, option {
    outline: none;
    &:focus {
      outline: none;
    }
  }

}